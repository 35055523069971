"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var FileDrop_1 = require("./FileDrop");
var onFileDrop = function (types, listener) {
    var _a = (0, FileDrop_1.useFileDrop)(), on = _a.on, off = _a.off;
    (0, react_1.useEffect)(function () {
        types.forEach(function (type) { return on(type, listener); });
        return function () { return types.forEach(function (type) { return off(type, listener); }); };
    }, []);
};
exports.default = onFileDrop;
