"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFileType = void 0;
var CONSTANTS_1 = require("stremio/common/CONSTANTS");
var SIGNATURES = CONSTANTS_1.MIME_SIGNATURES;
var isFileType = function (buffer, type) {
    var signatures = SIGNATURES[type];
    return signatures.some(function (signature) {
        var array = new Uint8Array(buffer);
        var signatureBuffer = Buffer.from(signature, 'hex');
        var bufferToCompare = array.subarray(0, signatureBuffer.length);
        return Buffer.compare(signatureBuffer, bufferToCompare) === 0;
    });
};
exports.isFileType = isFileType;
