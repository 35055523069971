"use strict";
// Copyright (C) 2017-2025 Smart code 203358507
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useOrientation = function () {
    var _a = (0, react_1.useState)(window.innerHeight), windowHeight = _a[0], setWindowHeight = _a[1];
    var _b = (0, react_1.useState)(window.innerWidth), windowWidth = _b[0], setWindowWidth = _b[1];
    var orientation = (0, react_1.useMemo)(function () {
        if (windowHeight > windowWidth) {
            return 'portrait';
        }
        else {
            return 'landscape';
        }
    }, [windowWidth, windowHeight]);
    (0, react_1.useEffect)(function () {
        var handleResize = function () {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth, window.innerHeight]);
    return orientation;
};
exports.default = useOrientation;
